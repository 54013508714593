.stream {
  background: $color-1;
  width: 100%;
  // border: solid red 1px;

  .container {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    border: solid $color-1 1px;
    padding: 0 2rem;
    width: 100%;

    .card.grid {
      height: 35rem;
      margin: 1rem 0;
      position: relative;
      border-radius: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }

      .play {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        font-size: 2rem;
        color: #fff;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .overlay {
        text-align: left;
        padding: 0 20px;
        z-index: 2;
        color: #fff;
        height: 100%;

        .icons {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 1rem 0;
          margin: 0;

          .live {
            background: red;
            padding: 0.3rem 1rem;
            border-radius: 10rem;
          }
        }
        .text {
          position: absolute;
          bottom: 2.5rem;

          p {
            font-size: 14px;
            font-weight: 300;
            margin: 0;

            &.hash {
              font-style: italic;
              font-size: 12px;
              font-weight: 100;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .stream {
    .container {
      .card.grid {
        height: 25rem;

        .overlay {
          bottom: 10px;
          padding: 0 20px;
          z-index: 2;
          color: #fff;
        }
      }
    }
  }
}
