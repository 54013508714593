.app {
  width: 75%;
  margin: 0 auto;
  text-align: center;

  //   border: solid red 1px;

  h3 {
    font-size: 1.5rem;
    text-align: left;
    font-weight: 600;
  }
}

@media only screen and (max-width: 800px) {
  .app {
    width: 100%;
  }
}

@import "./navbar.scss";
@import "./home.scss";
@import "./stream.scss";
@import "./newsfeed.scss";