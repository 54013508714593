.home {
  width: 100%;
  overflow: hidden;
  background: #f5f5f5;

  .home-slide {
    position: relative;

    .box {
      text-align: center;
      position: relative;
      margin: 0;

      &.multi {
        &.box::after {
          background: rgba($color: #000000, $alpha: 0);
        }

        .card {
          padding: 0 5px;
          border-radius: 1rem;

          p {
            text-align: left;
            text-decoration: none;
          }

          img {
            border-radius: inherit;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 40vh;
      object-fit: cover;
    }

    .box::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.6);
    }

    .overlay {
      position: absolute;
      text-align: left;
      //   height: 100%;
      bottom: 10px;
      padding: 0 20px;
      z-index: 2;
      color: #fff;

      p {
        font-size: 14px;
        font-weight: 300;
        margin: 0;

        &.hash {
          font-style: italic;
          font-size: 12px;
          font-weight: 100;
        }
      }

      h3 {
        font-weight: 500;
        color: rgb(253, 246, 126);
      }

      // border: solid red 1px;
    }

    .play {
      //   border: solid red 1px;
      position: absolute;
      z-index: 2;
      cursor: pointer;
      font-size: 2rem;
      color: #fff;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .multi-div {
    padding: 0.5rem 1rem;

    p {
      text-align: right;
      text-decoration: underline;
      font-size: 0.85rem;
    }
  }

  .morefeed {
    width: 100%;
    padding: 0 1rem;

    h3 {
      padding: 0.5rem 1rem;
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      gap: 5px;

      .card.grid {
        height: 20rem;
        position: relative;
        border-radius: 1rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: inherit;
        }

        .play {
          position: absolute;
          z-index: 2;
          cursor: pointer;
          font-size: 2rem;
          color: #fff;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .overlay {
          position: absolute;
          text-align: left;
          //   height: 100%;
          bottom: 10px;
          padding: 0 20px;
          z-index: 2;
          color: #fff;

          p {
            font-size: 14px;
            font-weight: 300;
            margin: 0;

            &.hash {
              font-style: italic;
              font-size: 12px;
              font-weight: 100;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .home {
    .morefeed {
      .grid-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
